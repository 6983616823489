import  React,{ useEffect } from'react';
import Navigation from './config/navigation'; // Adjust the path as necessary
import { Provider } from 'react-redux';
import store from './config/redux/store/store';
import ReactGA from 'react-ga4';
//import useEffect from'react';



function App() {
  useEffect(() => {
    ReactGA.initialize('G-X22186FH1L'); // Replace with your GA4 Measurement ID
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
  }, []);

  return (
    <>
      <Provider store={store}>
      <Navigation />
      </Provider>
    </>
  );
}

export default App;