import React from 'react';

const About = () => {
    return (
        <div className="bg-gray-100 py-10 px-6 lg:px-32">
            <div className="container mx-auto">
                {/* Header Section */}
                <h1 className="text-3xl font-bold text-left mb-6">Voice for Change</h1>
                <h2 className="text-2xl italic text-left mb-6">“Empowering Voices, Inspiring Change”</h2>
                <p className="text-gray-700 mb-6">Publication of the Rongmei People (Facebook Page)</p>

                {/* Introduction Section */}
                <section className="mb-8">
                    <p className="mb-6 text-gray-700">
                        We are committed to providing quality papers for free to interested readers. Our objective is to showcase the talents and creative works of tribal communities in writing and the arts.
                    </p>
                </section>

                {/* About Section */}
                <section className="mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-left">About Voice for Change</h2>
                    <p className="mb-6 text-gray-700">
                        "Voice for Change" is a website that echoes the need for societal transformation, emphasizing changes that benefit society at large while also being dedicated to the upliftment of the Rongmei Naga tribe in particular, and the Naga communities in general, as well as the diverse communities of North East India. Published by the Rongmei People (Facebook Page), it serves as a powerful voice advocating for positive change and progress in the region.
                    </p>
                    <p className="mb-6 text-gray-700">
                        The website aims to inspire and engage a broad spectrum of contributors, including experts, teachers, leaders of civil society organizations, scholars, and especially the student community. By encouraging the creation of diverse content—such as research papers, articles, opinion pieces, book reviews, short stories, and poetry—Voice for Change fosters rich intellectual dialogue on critical issues. 
                    </p>
                </section>

                {/* Mission Section */}
                <section className="mb-8">
                    <p className="mb-6 text-gray-700">
                        Voice for Change motivates contributors to explore topics like North East Indian culture, societal shifts, and political issues to promote a more peaceful, tolerant, inclusive, secure, and sustainable society. The website envisions itself as a hub for knowledge dissemination and a catalyst for change, promoting informed discussions that contribute to the betterment of North East Indian societies.
                    </p>
                </section>

                {/* Vision Section */}
                <section className="mb-8">
                    <p className="mb-6 text-gray-700">
                        We aim to shed light on the changes needed to address societal challenges and drive progress. By actively engaging experts and the academic community, Voice for Change positions itself as a valuable resource that not only identifies issues but also offers potential solutions, guiding positive transformation in North East India.
                    </p>
                </section>

                {/* Publishing Goals Section */}
                <section className="mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-left">What We Publish</h2>
                    <p className="mb-6 text-gray-700">
                        We are keen to publish articles, creative writings, and commentary that:
                    </p>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-4">Encourage young people to pursue their dreams;</li>
                        <li className="mb-4">Contribute to societal advancement, particularly through describing good practices and identifying necessary changes;</li>
                        <li className="mb-4">Critique dominant views of North Eastern tribal social norms to identify weaknesses;</li>
                        <li className="mb-4">Help tribal communities identify solutions to shared challenges.</li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default About;
