import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const navigate = useNavigate();
  const raw_data = useSelector((state) => state.apiSlice);

  // State to control "More" button
  const [showMore, setShowMore] = useState(false);
  const isMobile = useIsMobile();

  const handleClick = () => {
    navigate(`/`);
  };

  // Get categories
  const categories = raw_data?.data?.data?.category || [];

  // Split categories into first two and the rest
  const firstTwoCategories = categories.slice(0, 2);
  const remainingCategories = categories.slice(2);

  // Combine 'All' link with first two categories
  const initialLinks = [
    { name: 'All', slug: '' },
    ...firstTwoCategories,
  ];

  // All links including 'All' and all categories
  const allLinks = [
    { name: 'All', slug: '' },
    ...categories,
  ];

  return (
    <>
      {!raw_data?.loading && (
        <>
          <nav className="bg-gray-800 text-gray-800 p-1 ps-5">
            Email: voiceforchange@ruangmei.com
          </nav>
          <header className="w-full text-center mb-5">
            <h1
              className="text-6xl font-bold text-gray-900 pt-5 cursor-pointer"
              onClick={handleClick}
            >
              Voice for Change
            </h1>
            <p className="text-lg text-gray-700 my-3">
              Empowering Voices, Inspiring Change
            </p>

            {/* Conditionally render navigation if categories are available */}
            {categories.length > 0 && (
              <>
                {/* Mobile Initial Navigation */}
                {!showMore && isMobile && (
                  <nav
                    className="
                      flex
                      border-b border-t border-gray-300
                      justify-center
                      py-0 text-lg font-bold text-gray-700
                      space-x-4 sm:space-x-8
                    "
                  >
                    {/* Initial Links: 'All' and first two categories */}
                    {initialLinks.map((link) => (
                      <Link
                        key={link.slug || 'all'}
                        to={link.slug ? `/cat/${link.slug}` : '/'}
                        className="hover:text-blue-500"
                      >
                        {link.name}
                      </Link>
                    ))}

                    {/* Show 'More' button on mobile */}
                    {remainingCategories.length > 0 && (
                      <button
                        onClick={() => setShowMore(true)}
                        className="hover:text-blue-500"
                      >
                        More
                      </button>
                    )}
                  </nav>
                )}

                {/* All Links in Mobile Style */}
                {isMobile && showMore && (
                  <nav
                    className="
                      flex flex-col
                      items-center
                      text-lg font-bold text-gray-700
                      space-y-2 mt-2
                    "
                  >
                    {allLinks.map((link) => (
                      <Link
                        key={link.slug || 'all'}
                        to={link.slug ? `/cat/${link.slug}` : '/'}
                        className="hover:text-blue-500"
                        onClick={() => setShowMore(false)} // Collapse nav on click
                      >
                        {link.name}
                      </Link>
                    ))}

                    {/* 'Less' button to collapse */}
                    <button
                      onClick={() => setShowMore(false)}
                      className="hover:text-blue-500"
                    >
                      Less
                    </button>
                  </nav>
                )}

                {/* Desktop View: Show all categories without 'More' button */}
                {!isMobile && (
                  <nav
                    className="
                      flex
                      border-b border-t border-gray-300
                      justify-center
                      py-0 text-lg font-bold text-gray-700
                      space-x-4 sm:space-x-8
                    "
                  >
                    {allLinks.map((link) => (
                      <Link
                        key={link.slug || 'all'}
                        to={link.slug ? `/cat/${link.slug}` : '/'}
                        className="hover:text-blue-500"
                      >
                        {link.name}
                      </Link>
                    ))}
                  </nav>
                )}
              </>
            )}
          </header>
        </>
      )}
    </>
  );
};

// Custom hook to detect mobile view
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // Tailwind's 'sm' breakpoint is 640px
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
}

export default NavBar;
