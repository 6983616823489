import React from 'react';

const MissionVision = () => {
    return (
        <div className="bg-gray-100 py-10 px-6 lg:px-32">
            <div className="container mx-auto">
                {/* Page Header */}
                {/* <h1 className="text-4xl font-bold text-left mb-6">Mission & Vision</h1> */}

                {/* Mission and Vision Content */}
                <section className="p-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">Our Mission</h2>
                    <p className="mb-6 text-gray-700 text-left">
                        Voice for Change aims to be a transformative force, advocating for positive societal change within the North East Indian region. It provides a dynamic platform dedicated to fostering intellectual dialogue and inspiring active participation in pursuing beneficial transformations. Its primary aim is to advocate for change and the upliftment of the Rongmei Naga tribe, Naga communities, and all the diverse communities of North East India.
                    </p>

                    <p className="mb-6 text-gray-700 text-left">
                        The website’s core purpose is to be a potent voice for positive change and progress, with a specific focus on the Rongmei Naga tribe and the well-being of all communities in North East India.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4 text-left">Our Vision</h2>
                    <p className="mb-6 text-gray-700 text-left">
                        Voice for Change strives to inspire and engage a wide range of contributors, including experts, teachers, professors, scholars, and students. It stimulates intellectual dialogue on critical issues by encouraging diverse content such as research papers, articles, opinion pieces, book reviews, short stories, and poetry.
                    </p>

                    <p className="mb-6 text-gray-700 text-left">
                        It also seeks to explore and understand the rich cultural heritage of the region while addressing contemporary societal challenges. The journal underscores its commitment to inclusivity by focusing on the specific needs of the Rongmei Naga tribe and Naga communities, while also embracing the diverse communities of North East India.
                    </p>

                    <p className="text-gray-700 text-left">
                        By engaging experts and the academic community, Voice for Change aims to serve as a valuable resource for identifying societal issues and offering potential solutions, guiding and facilitating positive transformation within North East Indian societies.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default MissionVision;
