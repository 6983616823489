import React from 'react';

const ContactUs = () => {
    return (
        <div className="bg-gray-100 py-10 px-6 lg:px-32">
            <div className="container mx-auto">
                {/* Page Header */}
                <h1 className="text-2xl font-bold text-left mb-6">Contact Us</h1>

                {/* Introduction Section */}
                <section className="mb-8">
                    <p className="mb-6 text-gray-700">
                        We welcome your feedback, inquiries, and contributions. Whether you’re a potential contributor, reader, or supporter of Voice for Change, we’re here to connect with you.
                    </p>
                </section>

                {/* General Inquiries Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">General Inquiries</h2>
                    <p className="mb-6 text-gray-700">
                        For general questions or information about Voice for Change, feel free to reach out to us via email. We’re happy to answer any queries regarding our mission, submission process, or how you can get involved in our efforts to foster positive change.
                    </p>
                    <p className="mb-6 text-gray-700">
                        <strong>Email:</strong> <a href="mailto:voiceforchange2024@gmail.com" className="text-blue-500 underline">voiceforchange2024@gmail.com</a>
                    </p>
                </section>

                {/* Optional: Add a Contact Form (if desired in future) */}
                {/* <section className="mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-left">Send Us a Message</h2>
                    <form>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Your Name</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="name"
                                type="text"
                                placeholder="Enter your name"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Your Email</label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                            <textarea
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="message"
                                rows="5"
                                placeholder="Enter your message"
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Send
                        </button>
                    </form>
                </section> */}
            </div>
        </div>
    );
};

export default ContactUs;
