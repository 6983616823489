import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from '../../screens/home';
import About from '../../screens/about/'; // Correct path for About component
import ReadScreen from '../../screens/article/read';
import CategoryScreen from '../../screens/home/category';
import NavBar from './navbar';
import Footer from './footer';
import MissionVision from '../../screens/mission';
import BecomeContributor from '../../screens/contributor';
import ContactUs from '../../screens/contactus';
import SubmissionGuidelines from '../../screens/guidelines';

const Navigation = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/about" element={<About />} /> 
        <Route path="/atl/:slug" element={<ReadScreen />} /> 
        <Route path="/cat/:name" element={<CategoryScreen />} /> 
        <Route path="/mission-vision" element={<MissionVision />} />
        <Route path="/become-contributor" element={<BecomeContributor />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/submission-guidelines" element={<SubmissionGuidelines />} />  {/* Added Submission Guidelines route */}

      </Routes>
      <Footer />
    </Router>
  );
};

export default Navigation;
