import React from 'react';

const BecomeContributor = () => {
    return (
        <div className="bg-gray-100 py-10 px-6 lg:px-32">
            <div className="container mx-auto">
                {/* Page Header */}
                <h1 className="text-4xl font-bold text-left mb-6">Join the Movement: Become a Contributor</h1>

                {/* Introduction Section */}
                <section className="mb-8">
                    <p className="mb-6 text-gray-700">
                        At Voice for Change, we believe in the power of diverse voices to inspire meaningful societal transformation. We invite contributors from all walks of life—scholars, students, writers, artists, and leaders of civil society—to join us in fostering intellectual dialogue and advocating for positive change.
                    </p>
                    <p className="mb-6 text-gray-700">
                        By contributing to Voice for Change, you can:
                    </p>

                    {/* Contribution Benefits */}
                    <ul className="list-disc ml-6 mb-6 text-gray-700">
                        <li className="mb-2">
                            <strong>Showcase Your Work:</strong> Share your research, creative writing, opinion pieces, and artwork with a wide audience interested in North East Indian culture, societal development, and the challenges facing the region.
                        </li>
                        <li className="mb-2">
                            <strong>Be a Voice for Change:</strong> Participate in important discussions that aim to address societal issues, uplift the Rongmei Naga tribe, and promote the well-being of all diverse communities in North East India.
                        </li>
                        <li className="mb-2">
                            <strong>Engage with a Community:</strong> Connect with a growing community of experts, students, and thought leaders passionate about promoting progress, tolerance, and inclusivity.
                        </li>
                    </ul>
                </section>

                {/* What You Can Contribute Section */}
                <section className="mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-left">What You Can Contribute:</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-4">
                            <strong>News Submissions:</strong> We welcome timely news reports and stories highlighting key events, issues, or achievements relevant to North East India, with a focus on accurate reporting and fair representation of facts. News submissions should be concise, no longer than 1000 words.
                        </li>
                        <li className="mb-4">
                            <strong>Opinion Pieces:</strong> 1000–2500 words addressing societal challenges and offering constructive solutions.
                        </li>
                        <li className="mb-4">
                            <strong>Research Articles:</strong> Up to 3000 words on critical societal issues, cultural studies, or political analysis.
                        </li>
                        <li className="mb-4">
                            <strong>Short Stories:</strong> Original works up to 5000 words that reflect cultural narratives and personal experiences.
                        </li>
                        <li className="mb-4">
                            <strong>Poetry:</strong> Up to 600 words, celebrating creative expression and themes relevant to North East India.
                        </li>
                        <li className="mb-4">
                            <strong>Art and Photography:</strong> Submit artwork or photography related to North Eastern themes, expressing perspectives on culture, identity, and change.
                        </li>
                        <li className="mb-4">
                            <strong>Book Reviews:</strong> Up to 1500 words, exploring literature that reflects or critiques societal issues in the region.
                        </li>
                    </ul>
                </section>

                {/* How to Become a Contributor Section */}
                <section className="mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-left">How to Become a Contributor:</h2>
                    <ol className="list-decimal ml-6 text-gray-700">
                        <li className="mb-4">
                            <strong>Choose Your Format:</strong> Decide whether you want to submit an article, opinion piece, short story, poem, artwork, or review.
                        </li>
                        <li className="mb-4">
                            <strong>Prepare Your Submission:</strong> Follow the specific guidelines for each submission type (see our Submission Guidelines for details).
                        </li>
                        <li className="mb-4">
                            <strong>Submit Your Work:</strong> Send your manuscript or artwork via email to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a> for articles, poetry, and opinion pieces, or for artwork and photography.
                        </li>
                        <li className="mb-4">
                            <strong>Collaborate with Us:</strong> Our editorial team will review your submission, and once approved, work with you to finalize your contribution for publication.
                        </li>
                    </ol>
                </section>

                {/* Why Contribute Section */}
                <section className="mb-8">
                    <h2 className="text-3xl font-semibold mb-4 text-left">Why Contribute?</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-4">
                            <strong>No Fees:</strong> We are committed to providing free access to all our published content, and there are no charges for submitting or publishing your work.
                        </li>
                        <li className="mb-4">
                            <strong>Empower Change:</strong> Your contribution will be part of a larger movement advocating for the upliftment of tribal communities and promoting positive transformations in North East India.
                        </li>
                        <li className="mb-4">
                            <strong>Recognition:</strong> Gain visibility for your ideas, research, and creative work, and become part of an ongoing effort to inspire change and celebrate cultural diversity.
                        </li>
                    </ul>
                </section>

                {/* Closing Invitation */}
                <section className="mb-8">
                    <p className="text-lg text-gray-700">
                        Join us today as a contributor to Voice for Change, and let your voice be heard in shaping the future of North East India.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default BecomeContributor;
