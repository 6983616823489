import { Link } from 'react-router-dom'; // Import React Router's Link component

const Footer = () => {
    return (
        <footer className="bg-blue-950 text-white text-center py-10">
            <div className="container mx-auto">
                {/* Links Section */}
                <ul className="mb-6 space-y-2">
                    {/* <li>
                        <Link to="/revocation-form" className="hover:text-gray-500">All right reserve @ Voice for change 2024</Link>
                    </li> */}
                    <li>
                        <Link to="/about" className="hover:text-white-500">About</Link>
                    </li>
                    
                    <li>
                        <Link to="/mission-vision" className="hover:text-white-500">Mission & Vision</Link>
                    </li>
                   
                    <li>
                        <Link to="/become-contributor" className="hover:text-white-500">Join the Movement: Become a contributor</Link>
                    </li>
                    <li>
                        <Link to="/submission-guidelines" className="hover:text-white-500">Submission Guidelines</Link>
                    </li>
                    <li>
                        <Link to="https://whatsapp.com/channel/0029Vat5iAC6WaKoxafcDl1f" target='_blank' className="hover:text-white-500">Join Our Community</Link>
                    </li>
                    <li>
                        <Link to="/contact-us" className="hover:text-white-500">Contact Us</Link>
                    </li>
                    
                </ul>

                {/* Social Media Icons */}
                <div className="mb-6">
                    <ul className="flex justify-center space-x-6">
                        <li>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook-square text-2xl hover:text-gray-500"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram text-2xl hover:text-gray-500"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://snapchat.com" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-snapchat text-2xl hover:text-gray-500"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-pinterest text-2xl hover:text-gray-500"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Legal Information */}
                <div className="text-gray-500 text-sm">
                    <p>Copyright Voice for change 2024</p>
                    {/* <p>
                        <Link to="/terms" className="hover:text-gray-400">Terms & Conditions</Link> | 
                        <Link to="/privacy" className="hover:text-gray-400"> Privacy</Link> | 
                        <Link to="/legal-notice" className="hover:text-gray-400"> Legal Notice</Link>
                    </p> */}
                </div>

              
            </div>
        </footer>
    );
};

export default Footer;
