import React from 'react';

const SubmissionGuidelines = () => {
    return (
        <div className="bg-gray-100 py-10 px-6 lg:px-32">
            <div className="container mx-auto">
                {/* Page Header */}
                <h1 className="text-2xl font-bold text-left mb-6">Submission Guidelines for Voice for Change</h1>

                {/* Introduction */}
                <p className="mb-6 text-gray-700">
                    Voice for Change welcomes submissions in various formats, offering a platform for diverse voices and creative expressions. Please review the detailed guidelines for each submission type below:
                </p>

                {/* Articles Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">1. Articles</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-2"><strong>Length:</strong> Up to 3000 words</li>
                        <li className="mb-2"><strong>Submission:</strong> Send as an email attachment in Microsoft Word (.docx) format to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a></li>
                        <li className="mb-2"><strong>Copyright:</strong> Ensure the work is original and does not infringe on any existing copyrights.</li>
                        <li className="mb-2"><strong>Format:</strong> Include the article's title, author's name, and email address.</li>
                    </ul>
                </section>

                {/* Poetry Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">2. Poetry</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-2"><strong>Length:</strong> Up to 600 words</li>
                        <li className="mb-2"><strong>Submission:</strong> Email as an attachment in Microsoft Word (.docx) format to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a></li>
                        <li className="mb-2"><strong>Copyright:</strong> All poems must be original, free from plagiarism and offensive language.</li>
                        <li className="mb-2"><strong>Format:</strong> Include the poem's title, author’s name, position/post, institutional affiliation, email address, and a brief summary with explanations for key terms.</li>
                    </ul>
                </section>

                {/* Art and Photography Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">3. Art and Photography</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-2"><strong>Themes:</strong> Expressionism, Symbolism, Impressionism, Post-Impressionism, Contemporary Art, Abstract Art, Pop Art, Sketches, and Photography connected to North Eastern regions.</li>
                        <li className="mb-2"><strong>Submission:</strong> Submit digital artwork in JPEG or PNG format to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a></li>
                        <li className="mb-2"><strong>Copyright:</strong> Artists must agree to reproduction rights with proper credit.</li>
                        <li className="mb-2"><strong>Format:</strong> Images should be at least 2,000 pixels wide. Include the artist's name, artwork title, and a brief description.</li>
                    </ul>
                </section>

                {/* Book Reviews Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">4. Book Reviews</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-2"><strong>Length:</strong> Up to 1500 words</li>
                        <li className="mb-2"><strong>Submission:</strong> Send reviews in Microsoft Word (.docx) format to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a></li>
                        <li className="mb-2"><strong>Copyright:</strong> Ensure the review is original and does not infringe on any copyrights.</li>
                        <li className="mb-2"><strong>Format:</strong> Include the book review's title, author’s name, position/post, institutional affiliation, and email address.</li>
                    </ul>
                </section>

                {/* Short Stories Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">5. Short Stories</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-2"><strong>Length:</strong> Up to 5000 words</li>
                        <li className="mb-2"><strong>Submission:</strong> Send short stories as email attachments in Microsoft Word (.docx) format to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a></li>
                        <li className="mb-2"><strong>Copyright:</strong> Stories must be original and free from copyright infringement.</li>
                        <li className="mb-2"><strong>Format:</strong> Include the story's title, author's name, position/post, institutional affiliation, and email address.</li>
                    </ul>
                </section>

                {/* Opinion Pieces Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4 text-left">6. Opinion Pieces</h2>
                    <ul className="list-disc ml-6 text-gray-700">
                        <li className="mb-2"><strong>Length:</strong> 1000–2500 words</li>
                        <li className="mb-2"><strong>Submission:</strong> Submit your opinion piece in Microsoft Word (.docx) format to <a href="mailto:v4csubmission@gmail.com" className="text-blue-500 underline">v4csubmission@gmail.com</a></li>
                        <li className="mb-2"><strong>Format:</strong> Include a title, your name, position/post, institutional affiliation, and email address. Ensure the content is original and does not infringe on existing copyrights.</li>
                    </ul>
                </section>

                {/* Closing Message */}
                <section>
                    <p className="text-gray-700 text-lg">
                        By following these guidelines, you can contribute to meaningful discussions and showcase your work on Voice for Change. We look forward to receiving your submission!
                    </p>
                </section>
            </div>
        </div>
    );
};

export default SubmissionGuidelines;
